import React, { Component } from "react";
import { setTitle } from "../../common/js/tools";
import Header from "../../components/header";
import "./index.less";
import logo from "../../common/images/unitedOfficial/logo.png";
import top1 from "../../common/images/unitedOfficial/top1.png";
import top2 from "../../common/images/unitedOfficial/top2.png";
import top3 from "../../common/images/unitedOfficial/top3.png";
import top4 from "../../common/images/unitedOfficial/top4.png";

class UnitedOfficial extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navList: [{
                id: "home",
                name: "Home"
            }, {
                id: "news",
                name: "Company News"
            }, {
                id: "about",
                name: "About"
            }, {
                id: "more",
                name: "More services"
            }], // 顶部导航栏
            navIndex: 0, // 选中过的nav
            officialName: "United Srj Lending Investor Corp.", // 网站名
            sectionList: [{
                navIndex: 0,
                id: "home",
                imgSrc: top1,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 1,
                id: "news",
                imgSrc: top2,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 2,
                id: "about",
                imgSrc: top3,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 3,
                id: "more",
                imgSrc: top4,
                isInteractive: false, // 是否交互
            }],
            isModal: false,
            iwidth: 0,
        }
    }
    componentDidMount() {
        setTitle(this.state.officialName);
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        })
        window.addEventListener("resize", this.resize.bind(this));
    }
    // 滑动
    switchNav(item, index) {
        if(this.state.iwidth <= 1045) {
            this.scrollToAnchor(item, index);
        } else {
            this.setState({
                navIndex: index,
            });
        }
    }
    scrollToAnchor(item, index) {
        this.setState({
            navIndex: index,
        });
        let elementId = item.id;
        let anchorElement = document.getElementById(elementId);
        if (anchorElement) {
            anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }
    // 监听宽度变化
    resize() {
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        });
    }
    // 跳出弹框
    showModal() {
        this.setState({
            isModal: true,
        })
    }
    // 关闭弹框
    closeModal() {
        this.setState({
            isModal: false,
        })
    }
    render() {
        return (
            <div className="P-visiomed-wrap">
                <Header
                logoLeft
                logo={logo}
                // name={this.state.officialName}
                list={this.state.navList}
                navIndex={this.state.navIndex}
                isWidth={this.state.iwidth}
                switchNav={this.switchNav.bind(this)} />
                {this.state.iwidth <= 1045 ? (
                    <section className="P-media-visiomed-wrap_content">
                        {this.state.sectionList.map((item, index) => (
                            <div key={"vIndex" + index} className="content_area">
                                {!item.isInteractive ? (
                                    <img src={item.imgSrc} id={item.id} className="content_area_img" />
                                ) : null}
                            </div>
                        ))}
                    </section>
                ) : (
                    <section className="P-visiomed-wrap_content">
                        {this.state.sectionList.map((item, index) => (
                            <div key={"vIndex" + index} className="content_area">
                                {!item.isInteractive ? (
                                    index == this.state.navIndex ? 
                                    <img src={item.imgSrc} id={item.id} className="content_area_img switchImg" /> :
                                    null
                                ) : null}
                            </div>
                        ))}
                    </section>
                )}
            </div>
        )
    }
}

export default UnitedOfficial;