import React, { useState, useEffect } from "react";
import listIcon from "../../common/images/listIcon_other.png";
import "./index.less";

const Header = (props) => {
    const { logo, name, list, children, navIndex, isWidth, logoLeft, logoRight } = props;
    const [isOpenNav, setIsOpenNav] = useState(false); // 是否打开小屏幕导航栏
    const [isScrollBg, setIsScrollBg] = useState(false); // 滚动时是否更换导航栏背景色
    useEffect(() => {
        // 渲染完成
        window.addEventListener("scroll", scrollToNav.bind(this), true);
        return () => {
            // 销毁
            window.removeEventListener("scroll", scrollToNav);
        }
    }, [])
    // 改变nav
    const changeNav = (item, index, isOpenNav) => {
        if(isOpenNav) {
            setIsOpenNav(false);
        }
        props.switchNav(item, index);
    }
    // 监听滚动变化
    const scrollToNav = (e) => {
        let isScrollBg = e.target.scrollTop > 0 ? true : false;
        setIsScrollBg(isScrollBg);
    }
    // 宽度小于1045点击icon弹出导航栏框
    const changeOpenNav = () => {
        setIsOpenNav(!isOpenNav);
    }
    return (
        <div className="M-header" style={{background: navIndex == 1 || navIndex == 3 || isScrollBg ? "#082E84" : "transparent"}}>
            {/* logo和网站名 */}
            {logoLeft || isWidth <= 1045 ? 
            <div className="M-header_navLogo">
                {logo ? <img src={logo} className={isWidth <= 1045 ? "media_logo" : "logo"} /> : null}
                {name ? <p className="navLogo_title">{name}</p> : null}
            </div> : null}
            {children}
            {/* nav列表 宽度小于1045则list展开 大于1045则铺开 */}
            {isWidth <= 1045 ? (
                <div className="M-header_mediaIcon">
                    <img src={listIcon} className="listIcon" onClick={changeOpenNav} />
                </div>
            ) : null}
            {isWidth > 1045 || (isWidth <= 1045 && isOpenNav) ?
            <ul 
            className={isWidth <= 1045 ? "M-header_mediaNavgation" : "M-header_navgation"}
            style={{justifyContent: isWidth > 1045 && logoRight ? "left" : "right"}}>
                {list.map((item, index) => {
                    return (
                        <li
                        key={"oheader" + index}
                        className={index == navIndex ? "slider" : "normal"}
                        onClick={changeNav.bind(this, item, index, isOpenNav)}>
                            <p>{item.name}</p>
                        </li>
                    )
                })}
            </ul> : null}
            {/* logo和网站名 */}
            {logoRight && isWidth > 1045 ? <div className="M-header_navLogo">
                {logo ? <img src={logo} className="logo" /> : null}
                {name ? <p className="navLogo_title">{name}</p> : null}
            </div> : null}
        </div>
    )
}

export default Header;